.Header-container {
   display: flex;
   align-items: center;
   /* align-items: center; */
   justify-content: space-between;
   margin-top: 10px;
   margin-bottom: 12px;
   border-radius: 10px;
   box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)
}

.Header-imgwrap {
   /* width: 30%; or whatever you choose */
   margin: 15px;
}

.Header-imgwrap img {
   display: block;
   width: 100%;
   max-width: 250px;
   /*actual image width*/
   max-height: 250px;
   /*actual image width*/
   height: auto;
   /* maintain aspect ratio*/
   margin: auto;
   /*optional centering of image*/
}

.HelpContact-container {
   display: flex;
   justify-content: flex-end;
   margin-bottom: -20px;
}