.SectorSubscriberList-container {
  display: flex;
  flex-direction: column;
}

.SectorSubscriber-Sector-container {
  margin-bottom: 16px;
}

.SectorSubscriber-meal-schedule-container {
  margin: 16px 0 16px 0;
}

.disabled-content {
  pointer-events: none;
  opacity: 0.5;
}