.Dashboard-container {
  display: flex;
  flex-direction: column
}

.Dashboard-button-row {
  display: flex;

}

.Dashboard-button-row-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 10px;
}

.Dashboard-button-container {
  margin: 10px;
  height: 85px;
  width: 95%;
  flex: 0.5;
}

.Dashboard-button {
  width: inherit;
  height: inherit;
}

.Dashboard-button-content-container {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  font-size: 0.65rem
}

.Dashboard-card-container {
  margin-bottom: 10px;
}

.important-notice-container {
  margin-bottom: 10px;
}

.Card-container {
  background: #ECE9E6;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #FFFFFF, #ECE9E6);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #FFFFFF, #ECE9E6);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.Dashboard-date-display-container {
  display: flex;
  border-bottom: solid 1px rgba(0, 0, 0, 0.15);
  margin-bottom: 15px;
}

.Dashboard-date-display {
  padding-bottom: 10px;
  padding-left: 15px;
}

.Dashboard-contributor-contianer {
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px rgba(0, 0, 0, 0.15);
  margin-bottom: 8px;
  padding-bottom: 15px
}

.Dashboard-contributor-contianer div {
  flex: 1;
}

.Dashboard-contributor-info {
  padding: 15px;
  font-size: 1.15rem;
  position: relative;
  top: 4px;
}

.Dashboard-menu-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: solid 1px rgba(0, 0, 0, 0.15);
  margin-bottom: 8px;
  padding-bottom: 15px
}

.Dashboard-menu-list {
  padding: 15px;
  font-size: 1.15rem;
  position: relative;
  top: 4px;
}

.Dashboard-menu-side-note {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  position: relative;
  min-width: 65%;
  margin-top: 10px;
  min-height: 90px;

}

.Dashboard-menu-side-note_header {
  text-align: center;
  padding-top: 10px;
  text-decoration: underline;
}

.Dashboard-menu-side-note_content {
  font-size: 0.90rem;
  padding: 10px;
  text-align: center;
}

.Dashboard-menu-side-note_content-left {
  font-size: 0.90rem;
  padding: 10px;

}

.Dashboard-menu-items {
  display: flex;
  flex-direction: column;
  padding-left: 8px;
}

.Dashboard-menu-items-each {
  font-size: 0.80rem;
  padding-bottom: 1px;
  content: '\2713';
  text-align: right;

}

.Dashboard-menu-items-each::before {
  margin-right: 5px;

  /* Adjust spacing between the checkmark and text */
}

.Dashboard-nomeal-contianer {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px rgba(0, 0, 0, 0.15);
  margin-bottom: 8px;
  padding-bottom: 15px
}

.Dashboard-instructionForSubscriber-container {
  justify-content: space-between;
  margin-bottom: 8px;
}

.Dashboard-no-details {
  padding: 15px;
}

.Dashboard-registration-button-content-container {
  display: flex;
  flex-direction: column-reverse;
  /* Keeps the order of items reversed */
  align-items: center;
  /* Center items horizontally */
  justify-content: center;
  /* Center items vertically */
  font-size: 1.5rem;
  /* Base font size */
  text-align: center;
  /* Center text horizontally */
  width: 100%;
  /* Ensure it takes the full width */
  color: #ECE9E6;
}

/* Responsive adjustments */
@media (max-width: 768px) {

  /* Example breakpoint for tablets */
  .Dashboard-registration-button-content-container {
    font-size: 1.5rem;
    /* Smaller font size for smaller screens */
  }
}

@media (max-width: 480px) {

  /* Example breakpoint for mobile devices */
  .Dashboard-registration-button-content-container {
    font-size: 1.25rem;
    /* Even smaller font size for mobile */
  }
}

/* Wrapper for positioning the badge relative to the button */
.registration-button-wrapper {
  position: relative;
  display: inline-block;

  /* Ensures the badge stays aligned with the button */
}

/* Style for the floating badge */
.Dashboard-badge {
  position: absolute;
  top: -35px;
  /* Adjust this value to control the vertical position of the badge */
  right: -10px;
  /* Adjust this value to control the horizontal position of the badge */
  background-color: #ff9800;
  /* Badge color */
  color: white;
  padding: 5px 10px;
  /* Smaller padding for a compact badge */
  font-size: 0.8rem;
  /* Smaller font size for the badge */
  font-weight: bold;
  border-radius: 50px;
  /* Circle-like shape */
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  /* Ensures the badge stays above the button */
  width: 25%;
}