.daily-meal-count-report-container {
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

}

.daily-meal-count-report-expand-more {
  display: flex;
  justify-content: right;
  align-items: center;
}

.daily-meal-count-report-count-details-container {
  display: flex;
  font-size: 0.80rem;
  font-weight: 400;
  justify-content: space-around;
  /* Align items along the main axis with space between */
  align-items: center;
  /* Align items along the cross axis in the center */
}

.daily-meal-count-report-count-details-container-color {
  display: flex;
  justify-content: space-around;
  font-size: 0.80rem;
  font-weight: 400;
  background-color: #29be4c;
}

.daily-meal-count-report-count-details-container-col-name {
  flex: 3;
  border: 1px solid #ccc;
  /* Add a thin border around the cells */

}

.daily-meal-count-report-count-details-container-col {
  flex: 1;
  text-align: center;
  border: 1px solid #ccc;
  /* Add a thin border around the cells */

}

.daily-meal-count-report-table-container {
  width: 100%;
  overflow-x: auto;
}

.daily-meal-count-report-table {
  table-layout: fixed;
  width: 100%;
}

.daily-meal-count-report-table-cell {
  width: auto;
}

.StyledTableRow {
  border: 1px solid #ccc;
  /* Add a thin border around the row */
}

.StyledTableCell {
  border: 1px solid #ccc;
  /* Add a thin border around the cells */
}

/* 

.daily-meal-count-report-container{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.daily-meal-count-no-report-container {
  padding: 20px;
  text-align: center;
}

.daily-meal-count-report-expand-more{
  display: flex;
  justify-content: space-around;
}

.daily-meal-count-report-count-details-container{
  display: flex;
  justify-content: space-around;
  font-size: 0.80rem;
  font-weight: 400;
}

.daily-meal-count-report-count-details-container-color{
  display: flex;
  justify-content: space-around;
  font-size: 0.80rem;
  font-weight: 400;
  background-color: red;
}

.daily-meal-count-report-count-details-container-col{
  flex:1
}
.daily-meal-count-report-count-details-container-col-name{
  flex:2
} */



/* Add this to your DailyMealCountReport.css */

.sector-key-cell {
  width: 400px;
  /* Adjust the width as needed */
  max-width: 400px;
  /* Ensure it doesn't exceed this width */
  word-wrap: break-word;
  white-space: normal;
}