.menu-schedule-week-navigator-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.daily-menu-container {
  margin-top: 20px;
}

.MenuSchedule-content-contianer {
  display: flex;
  justify-content: space-between;
}

.MenuSchedule-action-contianer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 15px;
  padding-bottom: 15px;
}

.MenuSchedule-action-review-cancel {
  height: 60px;
  color: #fff;
  border-radius: 5px;
  border: solid 1px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  padding: 5px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  background-color: #f50057;
  margin: 5px;

}

.MenuCard-Header-Meal-Cancelled {
  background-color: #f50057;
  color: #fff;
}

.MenuCard-Header-Meal-Availed {
  background-color: #29be4c;
  color: #fff;
}