.bold {
  font-weight: bold;
}

.font-size-30 {
  font-size: 30px;
}

.font-size-10 {
  font-size: 10px;
}

.page-break {
  page-break-after: always;
}

.daily-meal-count-report-print {
  display: block;
  align-items: right;
}