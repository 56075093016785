.Profile-avatarContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px;
    flex-direction: column;
}

.UserProfile-paper {
    padding-bottom: 25px;
}

.error-message {
    color: red;
    /* Makes the text red */
    font-size: 0.875em;
    /* Slightly smaller than normal text */
    margin-top: 0.25em;
    /* Adds spacing between the input and the error */
    display: block;
    /* Ensures the error message appears on a new line */
}

.blurb-message {
    color: #007bff;
    /* Makes the text blue */
    font-size: 0.875em;
    /* Slightly smaller than normal text */
    margin-top: 0.25em;
    /* Adds spacing between the input and the error */
    display: block;
    /* Ensures the error message appears on a new line */
    border: 1px solid #007bff;
    /* Adds a blue border */
    padding: 0.5em;
    /* Adds padding inside the box */
    border-radius: 5px;
    /* Rounds the corners of the box */
    background-color: #f9f9f9;
    /* Adds a light background color */
}

.label {
    color: gray;
    /* Sets "Eligible Dates for Selection" to gray */
}

.date-count {
    /* color: #007bff; */
    color: red;

    /* Sets {dateCount} to dark blue */
    font-weight: bold;
    /* Makes the number bold */
}

/* Media Query for small screens (max-width: 768px) */
@media (max-width: 768px) {
    .container {
        flex-direction: column;
        /* Stack items vertically */
        justify-content: center;
        /* Center items when stacked */
        padding: 2rem;
        /* Add padding for small screens */
    }

    .childItem {
        width: 100%;
        /* Makes each child take full width when stacked */
        padding: 1rem;
        /* Adjust padding for spacing */
    }

    .circle {
        flex: 1 1 20vw;
        /* Increase flexibility for smaller screens */
        max-width: 4rem;
        /* Limit the size of circles */
        font-size: 4vw;
        /* Increase font size for readability */
    }

    .line {
        flex: 1 1 10vw;
        /* More flexibility for lines */
        max-width: 2rem;
        /* Limit the width of the lines */
    }

    .stepLabel {
        font-size: 3vw;
        /* Increase font size for readability on small screens */
    }
}