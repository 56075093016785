.MenuDetails-header {
  margin-bottom: 15px;
  padding: 20px;
  text-align: center;
}

.MenuDetails-quantity-action-container {
  display: flex;
  justify-content: center;
  align-items: self-start;
  margin-top: 25px;
}

.MenuDetails-quantity-selector-container {
  /* display: flex;
  
  margin-left:10px; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  /* Add spacing between grid items */
  margin-left: 10px;

}

.MenuDetails-quantity-selector-radio {}


.MenuDetails-quantity-selector-radio-label {
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  margin-top: 10px;
  padding: 8px
}

.MenuDetails-quantity-selector-radio-label span:nth-child(2) {
  font-weight: 600;
}

.Review-validation-error {
  margin-bottom: 10px;
}